import Simple from '@/components/task-templates/Simple'

export default [
  {
    component: Simple,
    name: 'Округления',
    text: 'Дано число \\(a\\). Выведите его округлённым вниз, затем через пробел округлённым вверх, а затем на следующией строке округлённым по математическим правилам',
    input: '\\(a\\) - вещественное неотрицательное число',
    output: 'Три варианта округления',
    note: 'Для округления вниз использовать функцию Math.floor, для округления вверх Math.ceil, для округления по математическим правилам Math.round. Например: Math.floor(1.6) в результате даст 1',
    examples: [
      {
        input: '0.7',
        output: '0 1\n1'
      },
      {
        input: '5.1',
        output: '5 6\n5'
      },
      {
        input: '10.5',
        output: '10 11\n11'
      }
    ]
  },
  {
    component: Simple,
    name: 'Остаток',
    text: 'Дано число \\(n\\). Выведите остаток от деления \\(n\\) на 7',
    note: 'Остаток от деления в JavaScript вычисляется с помощью оператора %. Например: 13 % 5 даст в результате 3',
    input: '\\(n\\) - целое неотрицательное число',
    output: 'Остаток от деления на 7',
    examples: [
      {
        input: '14',
        output: '0'
      },
      {
        input: '17',
        output: '3'
      },
      {
        input: '5',
        output: '5'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вася и мороженое',
    text: 'У Васи было \\(r\\) рублей. Он решил купить мороженого на все свои деньги. Одно мороженое стоит 10 рублей. Сколько денег останется у Васи?',
    note: 'Например, было 5 рублей. На эти деньги он купит 0 мороженых и у него останется 5 рублей (ответ: 5). Если было 27 рублей, то купит две штуки, останется 7 рублей (ответ: 7). Если было 150 рублей, купит 15 штук, останется 0.',
    input: '\\(r\\) - целое неотрицательное число',
    output: 'Сколько денег останется у Васи',
    examples: [
      {
        input: '5',
        output: '5'
      },
      {
        input: '27',
        output: '7'
      },
      {
        input: '150',
        output: '0'
      },
      {
        input: '199',
        output: '9'
      }
    ]
  },
  {
    component: Simple,
    name: 'Таймштамп - секунды с последнего часа',
    text: 'В компьютерном мире очень часто для хранения даты и времени используют формат timestamp (таймштамп / временная метка). Это количество секунд (иногда миллисекунд), которые прошли с 1 января 1970 года 00:00:00 в нулевом часовом поясе. Этот формат намного экономичнее, чем хранение отдельно года, месяца, числа, часов, минут и секунд, поскольку для представления любого конкретного времени достаточно одного числа.<br>Дано число \\(t\\) - таймштамп какой-то даты и времени (в секундах). Определите сколько секунд прошло с начала последнего часа',
    input: '\\(t\\) - целое положительное число',
    output: 'Сколько секунд прошло с последнего часа',
    note: null,
    examples: [
      {
        input: '4567',
        output: '967'
      },
      {
        input: '1619633183',
        output: '383'
      },
      {
        input: '1584139518',
        output: '2718'
      }
    ]
  },
  {
    component: Simple,
    name: 'Таймштамп - текущее время (GMT+0)',
    text: 'Дано число \\(t\\) - таймштамп какой-то даты и времени (в секундах). Выведите текущее время - часы (от 0 до 23) и минуты (от 0 до 59) через пробел для нулевого часового пояса',
    input: '\\(t\\) - целое положительное число',
    output: 'Часы и минуты через пробел',
    note: null,
    examples: [
      {
        input: '4567',
        output: '1 16'
      },
      {
        input: '1619633183',
        output: '18 6'
      },
      {
        input: '1584139518',
        output: '22 45'
      }
    ]
  },
  {
    component: Simple,
    name: 'Таймштамп - текущее время (GMT+3)',
    text: 'Дано число \\(t\\) - таймштамп какой-то даты и времени (в секундах). Выведите текущее время - часы (от 0 до 23) и минуты (от 0 до 59) через пробел для московского часового пояса (+3 часа)',
    input: '\\(t\\) - целое положительное число',
    output: 'Часы и минуты через пробел',
    note: null,
    examples: [
      {
        input: '4567',
        output: '4 16'
      },
      {
        input: '1619633183',
        output: '21 6'
      },
      {
        input: '1584139518',
        output: '1 45'
      }
    ]
  },
  {
    component: Simple,
    name: 'Таймштамп - день недели',
    text: 'Дано число \\(t\\) - таймштамп какой-то даты и времени (в секундах). Выведите номер текущего дня недели для нулевого часового пояса<br>(0 - вс, 1 - пн, ..., 6 - суббота)',
    note: '1 января 1970-го года был четверг',
    input: '\\(t\\) - целое положительное число',
    output: 'Номер дня недели',
    examples: [
      {
        input: '4567',
        output: '4'
      },
      {
        input: '1619633183',
        output: '3'
      },
      {
        input: '1584139518',
        output: '5'
      },
      {
        input: '1529269035',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Десятки и единицы',
    text: 'Дано двузначное число \\(a\\). Выведите вначале левую цифру (десятки), а затем правую (единицы)',
    note: null,
    input: '\\(a\\) - целое положительное двузначное',
    output: 'Десятки и единицы через пробел.',
    examples: [
      {
        input: '38',
        output: '3 8'
      },
      {
        input: '10',
        output: '1 0'
      },
      {
        input: '91',
        output: '9 1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Предпоследняя цифра',
    text: 'Дано число \\(a\\) (\\(a \\ge 10\\)). Выведите его предпоследнюю цифру',
    note: null,
    input: '\\(a\\) - целое положительное, \\(a \\ge 10\\)',
    output: 'Предпоследняя цифра',
    examples: [
      {
        input: '10',
        output: '1'
      },
      {
        input: '65',
        output: '6'
      },
      {
        input: '7898',
        output: '9'
      }
    ]
  },
  {
    component: Simple,
    name: 'Третья с конца цифра',
    text: 'Дано число \\(a\\) (\\(a \\ge 100\\)). Выведите его третью с конца цифру',
    note: null,
    input: '\\(a\\) - целое положительное, \\(a \\ge 100\\)',
    output: 'Третья с конца цифра',
    examples: [
      {
        input: '100',
        output: '1'
      },
      {
        input: '654',
        output: '6'
      },
      {
        input: '7986',
        output: '9'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сколько чисел?',
    text: 'Даны числа \\(a\\) и \\(b\\) (\\(a < b\\)). Сколько получится целых чисел в диапазоне от \\(a\\) до \\(b\\) включительно, если брать только каждое второе, начиная от \\(a\\)?',
    note: null,
    input: '\\(a\\) и \\(b\\) - целые',
    output: 'Количество чисел',
    examples: [
      {
        input: '1, 5',
        output: '3'
      },
      {
        input: '-3, 4',
        output: '4'
      },
      {
        input: '90, 99',
        output: '5'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чётное - 0, нечётное - 1',
    text: 'Дано число \\(a\\). Выведите 1, если оно нечётное, либо 0, если чётное',
    note: null,
    input: '\\(a\\) - целое положительное',
    output: '0 или 1',
    examples: [
      {
        input: '0',
        output: '0'
      },
      {
        input: '1',
        output: '1'
      },
      {
        input: '5',
        output: '1'
      },
      {
        input: '10',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чётное - 1, нечётное - 2',
    text: 'Дано число \\(a\\). Выведите 1, если оно чётное, либо 2, если нечётное',
    note: null,
    input: '\\(a\\) - целое положительное',
    output: '1 или 2',
    examples: [
      {
        input: '0',
        output: '1'
      },
      {
        input: '1',
        output: '2'
      },
      {
        input: '5',
        output: '2'
      },
      {
        input: '10',
        output: '1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чётное - 1, нечётное - 0',
    text: 'Дано число \\(a\\). Выведите 0, если оно нечётное, либо 1, если чётное',
    note: null,
    input: '\\(a\\) - целое положительное',
    output: '0 или 1',
    examples: [
      {
        input: '0',
        output: '1'
      },
      {
        input: '1',
        output: '0'
      },
      {
        input: '5',
        output: '0'
      },
      {
        input: '10',
        output: '1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чётное - 2, нечётное - 1',
    text: 'Дано число \\(a\\). Выведите 1, если оно нечётное, либо 2, если чётное',
    note: null,
    input: '\\(a\\) - целое положительное',
    output: '1 или 2',
    examples: [
      {
        input: '0',
        output: '2'
      },
      {
        input: '1',
        output: '1'
      },
      {
        input: '5',
        output: '1'
      },
      {
        input: '10',
        output: '2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Следующая фотография',
    text: 'На сайте размещено \\(n\\) фотографий. Пользователь открыл фотографию с номером \\(x\\) (\\(1 \\le x \\le n\\)). Фотография с каким номером будет следующей? (если он открыл последнюю фотографию, следующей должна быть первая)',
    note: null,
    input: '\\(n\\), \\(x\\) - целые положительные числа',
    output: 'Номер следующей фотографии',
    examples: [
      {
        input: '5, 3',
        output: '4'
      },
      {
        input: '5, 4',
        output: '5'
      },
      {
        input: '10, 10',
        output: '1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Предыдущая фотография',
    text: 'На сайте размещено \\(n\\) фотографий. Пользователь открыл фотографию с номером \\(x\\) (\\(1 \\le x \\le n\\)). Фотография с каким номером будет предыдущей? (если он открыл первую фотографию, предыдущей должна быть последняя)',
    note: null,
    input: '\\(n\\), \\(x\\) - целые положительные числа',
    output: 'Номер предыдущей фотографии',
    examples: [
      {
        input: '5, 3',
        output: '2'
      },
      {
        input: '5, 1',
        output: '5'
      },
      {
        input: '10, 10',
        output: '9'
      }
    ]
  },
  {
    component: Simple,
    name: 'Фотография далеко впереди',
    text: 'На сайте размещено \\(n\\) фотографий. Пользователь открыл фотографию с номером \\(x\\) (\\(1 \\le x \\le n\\)) и пролистал вперёд на \\(m\\) фотографий. Фотография с каким номером сейчас открыта? (он мог пролистать все фотографии несколько раз)',
    note: null,
    input: '\\(n\\), \\(x\\), \\(m\\) - целые положительные числа',
    output: 'Номер открытой фотографии',
    examples: [
      {
        input: '5, 3, 10',
        output: '3'
      },
      {
        input: '5, 5, 5',
        output: '5'
      },
      {
        input: '10, 5, 17',
        output: '2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Фотография далеко сзади',
    text: 'На сайте размещено \\(n\\) фотографий. Пользователь открыл фотографию с номером \\(x\\) (\\(1 \\le x \\le n\\)) и пролистал назад на \\(m\\) фотографий. Фотография с каким номером сейчас открыта? (он мог пролистать все фотографии несколько раз)',
    note: null,
    input: '\\(n\\), \\(x\\), \\(m\\) - целые положительные числа',
    output: 'Номер открытой фотографии',
    examples: [
      {
        input: '5, 3, 10',
        output: '3'
      },
      {
        input: '5, 5, 5',
        output: '5'
      },
      {
        input: '10, 5, 17',
        output: '8'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обратный отсчёт',
    text: 'На сайте интернет-магазина запустили акцию с ограниченным временем (только \\(h\\) часов). Сколько времени будет показывать таймер обратного отсчёта, если от начала акции прошло \\(m\\) минут?',
    note: 'Количество минут не превышает время действия акции',
    input: '\\(h\\) - целое положительное, \\(m\\) - целое неотрицательное',
    output: 'Часы и минуты на таймере через пробел',
    examples: [
      {
        input: '5, 1',
        output: '4 59'
      },
      {
        input: '7, 333',
        output: '1 27'
      },
      {
        input: '1, 59',
        output: '0 1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Клетка на поле под курсором',
    text: 'На экране нарисовано поле с клетками (левый верхний угол первой клетки на точке (0; 0)). Размер клетки \\(n \\times n\\) пикселей. Пользователь кликнул по пикселю с координами \\(x\\) и \\(y\\). В какой столбец и в какой ряд он попал? (ряды и столбцы нумеровать с единицы)',
    note: 'Ось x направлена вправо, ось y - вниз',
    input: '\\(n\\) - целое положительное, \\(x\\), \\(y\\) - целые неотрицательные',
    output: 'Номер столбца и номер ряда через пробел',
    examples: [
      {
        input: '10, 5, 5',
        output: '1 1'
      },
      {
        input: '10, 56, 30',
        output: '6 4'
      },
      {
        input: '20, 99, 101',
        output: '5 6'
      }
    ]
  },
  {
    component: Simple,
    name: 'Клетка на поле под номером K',
    text: 'На экране нарисовано поле с клетками, по \\(n\\) клеток в ряду. Если нумеровать клетки с единицы по порядку слева направо, при переходе на следующий ряд продолжая нумерацию, то в каком ряду и в каком столбце будет клетка с номером \\(k\\)?',
    note: null,
    input: '\\(n\\), \\(k\\) - целые положительные числа',
    output: 'Номер ряда и номер столбца через пробел',
    examples: [
      {
        input: '5, 11',
        output: '3 1'
      },
      {
        input: '8, 19',
        output: '3 3'
      },
      {
        input: '10, 50',
        output: '5 10'
      }
    ]
  },
  {
    component: Simple,
    name: 'Страница с фотографиями',
    text: 'На сайте планируется разместить фотографии. Размер блока под фотографии \\(bw \\times bh\\), размер фотографий \\(pw \\times ph\\). Сколько фотографий полностью поместится в блоке?',
    note: null,
    input: '\\(bw\\), \\(bh\\), \\(pw\\), \\(ph\\) - целые положительные числа',
    output: 'Количество фотографий',
    examples: [
      {
        input: '500, 500, 300, 300',
        output: '1'
      },
      {
        input: '500, 500, 200, 200',
        output: '4'
      },
      {
        input: '1200, 700, 400, 350',
        output: '6'
      },
      {
        input: '1000, 500, 250, 200',
        output: '8'
      }
    ]
  },
  {
    component: Simple,
    name: 'Время через N минут',
    text: 'Часы сейчас показывают время \\(h\\) часов (\\(0 \\le h \\le 23\\)), \\(m\\) минут (\\(0 \\le m \\le 59\\)). Сколько они покажут через \\(n\\) минут?',
    note: null,
    input: '\\(h\\), \\(m\\) - целые неотрицательные, \\(n\\) - целое положительное',
    output: 'Часы и минуты через пробел',
    examples: [
      {
        input: '10, 30, 40',
        output: '11 10'
      },
      {
        input: '23, 45, 15',
        output: '0 0'
      },
      {
        input: '22, 15, 310',
        output: '3 25'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вперёд на N месяцев',
    text: 'На календаре год \\(y\\) и месяц \\(m\\) (\\(1 \\le m \\le 12\\)). Выведите год и месяц, которые будут на календаре через \\(n\\) месяцев',
    note: null,
    input: '\\(y\\), \\(m\\), \\(n\\) - целые положительные',
    output: 'Год и месяц через пробел',
    examples: [
      {
        input: '2020, 11, 2',
        output: '2021 1'
      },
      {
        input: '2008, 9, 144',
        output: '2020 9'
      },
      {
        input: '2018, 11, 1',
        output: '2018 12'
      }
    ]
  }
]
